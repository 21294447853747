import { isBefore } from 'date-fns/esm';
import React, { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DataCard from '../../../app/components/common/DataCard';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { DialogContext } from '../../../app/contexts/dialogContext';
import { ModalContext } from '../../../app/contexts/modalContext';
import { ToastContext } from '../../../app/contexts/toastContext';
import {
  formatDateWithoutYear,
  formatFullDate,
} from '../../../app/helpers/datetime';
import {
  fetchCustodies,
  fetchCustodyStats,
  retireCustody,
} from '../../../app/repositories/custody';
import {
  closeTokenStore,
  fetchAurachnidStore,
  fetchPotentialTokenBuyers,
  fetchTokenStores,
} from '../../../app/repositories/token';

export const PotentialTokenBuyerListPage = () => {
  const { showModal } = useContext(ModalContext);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useQuery('shortlistedBuyers', () =>
    fetchPotentialTokenBuyers()
  );

  const buyers = data?.data.data.buyers;

  console.log(buyers);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="flex flex-col">
      <div className="mb-4">
        <h2 className="text-2xl font-bold">Potential Token Buyers</h2>
      </div>
      {/* <div className="mb-4">
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
          <DataCard
            title="Total Token"
            content={stats?.total.sum.tokenAmount ?? 0}
          />
        </dl>
      </div> */}
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    User ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Amount of Token
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Requested At
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {buyers.map((buyer) => (
                  <tr key={buyer.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {buyer?.userId}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {buyer?.amount}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {formatFullDate(buyer?.createdAt)}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
