import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { ModalContext } from '../../../app/contexts/modalContext';
import { formatFullDate } from '../../../app/helpers/datetime';
import { fetchCurrencies } from '../../../app/repositories/currency';

export const CurrencyListPage = () => {
  const { showModal } = useContext(ModalContext);

  const { data, isLoading, isFetching, refetch } = useQuery(
    'currencies',
    fetchCurrencies
  );

  const currencies = data?.data?.data?.currencies;

  if (isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <div className="flex flex-col">
      <div className="mb-4">
        <h2 className="text-2xl font-bold">Currencies</h2>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Symbol
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Value (MYR)
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Last Updated
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currencies.map((currency) => (
                  <tr key={currency.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="text-sm text-gray-500">
                          {currency.symbol}
                        </div>
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {currency.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {currency.value}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatFullDate(currency.updatedAt)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-6">
                      <button
                        onClick={() =>
                          showModal('Edit Currency', 'CurrencyModal', {
                            currency: currency,
                          })
                        }
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Edit Value
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
