import React from 'react';
import { useQuery } from 'react-query';
import DataCard from '../../../app/components/common/DataCard';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { formatDateWithoutYear } from '../../../app/helpers/datetime';
import { fetchDashboardStats } from '../../../app/repositories/admin';
import { fetchCustodies } from '../../../app/repositories/custody';

const DashboardPage = () => {
  const { data, isLoading, isFetching } = useQuery(
    ['custodies'],
    fetchCustodies
  );

  const {
    data: statsData,
    isLoading: statsIsLoading,
    isFetching: statsIsFetching,
  } = useQuery('dashboardStats', fetchDashboardStats);

  if (isFetching || statsIsFetching) {
    return <LoadingIndicator />;
  }

  const custodies = data?.data?.data?.custodies;
  const stats = statsData?.data?.data;

  return (
    <div className="flex flex-col">
      <div>
        <h2 className="text-2xl font-bold">Overview</h2>
      </div>
      <div className="my-4">
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
          <DataCard title="Total Wakalah" content={stats.users} />
          <DataCard title="Total Complete Orders" content={stats.orders} />
          <DataCard title="Total Active Tokens" content={stats.tokens} />
        </dl>
      </div>
      <div className="my-4">
        <h2 className="text-2xl font-bold">Latest Custodies</h2>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Jewelry
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    No. of Token
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Maturity Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Buyback Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Tag
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Owner
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Hash
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {custodies.map((custody) => (
                  <tr key={custody.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div>
                          <div className="text-sm font-medium text-gray-900">
                            {custody.jewelryName}
                          </div>
                          <div className="text-sm text-gray-500">
                            {custody.jewelryWeight} gr
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {custody.tokenAmount}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {formatDateWithoutYear(custody.maturityDate)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {!!custody.buybackDate
                          ? formatDateWithoutYear(custody.buybackDate)
                          : '-'}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{custody.tag}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {custody.owner.name}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {custody.blockchainHash}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
