import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useContext } from 'react';
import { ModalContext } from '../../contexts/modalContext';
import AddCategoryModal from '../modals/AddCategoryModal';
import CurrencyFormModal from '../modals/CurrencyFormModal';
import CustodyFormModal from '../modals/CustodyFormModal';
import OpenStoreFormModal from '../modals/OpenStoreFormModal';
import VerifyUserIdentityModal from '../modals/VerifyUserDetailsModal';
import UserDetailsModal from '../modals/VerifyUserDetailsModal';

const ModalWrapper = () => {
  const { state, hideModal } = useContext(ModalContext);

  const renderComponent = (type) => {
    switch (type) {
      case 'AddCategory':
        return <AddCategoryModal {...state.props} />;

      case 'AddCustody':
        return <CustodyFormModal {...state.props} />;

      case 'CurrencyModal':
        return <CurrencyFormModal {...state.props} />;

      case 'OpenStore':
        return <OpenStoreFormModal {...state.props} />;

      case 'VerifyUserIdentity':
        return <VerifyUserIdentityModal {...state.props} />;

      // case 'ShowCheckoutQr':
      //   return <ShowCheckoutQrModal {...state.props} />;

      // case 'AddNewCategory':
      //   return <AddNewCategoryModal {...state.props} />;

      // case 'UpdateStock':
      //   return <UpdateStockModal {...state.props} />;

      // case 'DeleteProduct':
      //   return <DeleteProductModal {...state.props} />;

      // case 'ChangePassword':
      //   return <ChangePasswordModal {...state.props} />;

      // case 'DeactivateAccount':
      //   return <DeactivateAccountModal {...state.props} />;

      // case 'DeliverOrder':
      //   return <DeliverOrderModal {...state.props} />;

      // case 'ArriveOrder':
      //   return <ArriveOrderModal {...state.props} />;

      // case 'CompleteProfile':
      //   return <CompleteProfileModal {...state.props} />;

      default:
        return <div>Hello</div>;
    }
  };

  return (
    <Transition.Root show={state.show} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={state.show}
        onClose={() => null}
      >
        <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="text-left inline-block align-bottom sm:rounded-lg bg-white pt-5 pb-4 p-6 shadow-xl transform transition-all sm:align-middle sm:max-w-lg w-full sm:w-full sm:p-6 max-h-screen h-screen sm:h-auto overflow-auto">
              <div className="w-full">
                <div className="flex justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 px-2"
                  >
                    {state.title}
                  </Dialog.Title>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6 cursor-pointer"
                    onClick={hideModal}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>

                <hr className="-mx-4 sm:-mx-6 mt-3" />

                {renderComponent(state.type)}
              </div>
            </div>
          </Transition.Child>
        </div>

        {/* <div className="flex flex-col bg-white sm:rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all sm:max-w-xl sm:w-full sm:p-6 max-h-screen h-full sm:h-auto overflow-auto">
          <div>
            <div className="flex justify-between px-2">
              <h3 className="text-xl font-semibold">{state.title}</h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6 cursor-pointer"
                onClick={hideModal}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <hr className="-mx-4 sm:-mx-6 mt-3" />
          </div>
          {renderComponent(state.type)}
        </div> */}
      </Dialog>
    </Transition.Root>
  );
};

export default ModalWrapper;
