import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ModalContext } from '../../contexts/modalContext';
import LoadingSpinner from '../common/LoadingSpinner';
import { editCurrency } from '../../repositories/currency';
import { ToastContext } from '../../contexts/toastContext';
import { openTokenStore } from '../../repositories/token';

const schema = yup.object().shape({
  amountBuy: yup
    .number()
    .positive('Please enter a valid number')
    .typeError('Please enter a valid number'),
  amountSell: yup
    .number()
    .positive('Please enter a valid number')
    .typeError('Please enter a valid number'),
  buyPrice: yup
    .number()
    .positive('Please enter a valid number')
    .typeError('Please enter a valid number'),
  sellPrice: yup
    .number()
    .positive('Please enter a valid number')
    .typeError('Please enter a valid number'),
});

const OpenStoreFormModal = ({ currency }) => {
  const { hideModal } = useContext(ModalContext);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: currency, resolver: yupResolver(schema) });

  const openStoreMutation = useMutation(
    (formValues) => openTokenStore(formValues),
    {
      onError: (error) => {
        showToast(
          'ERROR',
          'Failed to opening token store',
          `${error.response?.data?.message || error.message}`
        );
      },
      onSuccess: () => {
        showToast('SUCCESS', 'Success', 'Store successfully opened');
        queryClient.invalidateQueries('tokenStores');
        hideModal();
      },
    }
  );

  const onSubmit = (formValues) => {
    openStoreMutation.mutate(formValues);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex-1 flex flex-col px-2 py-3 space-y-6"
    >
      <div>
        <label htmlFor="amountBuy" className="text-sm">
          Amount of Tokens to Buy
        </label>
        <input
          {...register('amountBuy')}
          type="number"
          step="any"
          id="amountBuy"
          className="focus:ring-green-500 focus:border-green-500 block w-full px-2 sm:text-sm border-gray-400 rounded"
          placeholder="Enter tokens to buy amount"
        />
        {errors.amountBuy ? (
          <span className="text-sm text-red-700">
            {errors.amountBuy.message}
          </span>
        ) : null}
      </div>
      <div>
        <label htmlFor="buyPrice" className="text-sm">
          Buy Price
        </label>
        <input
          {...register('buyPrice')}
          type="number"
          step="any"
          id="buyPrice"
          className="focus:ring-green-500 focus:border-green-500 block w-full px-2 sm:text-sm border-gray-400 rounded"
          placeholder="Enter buying price"
        />
        {errors.buyPrice ? (
          <span className="text-sm text-red-700">
            {errors.buyPrice.message}
          </span>
        ) : null}
      </div>
      <div>
        <label htmlFor="amountSell" className="text-sm">
          Amount of Tokens to Sell
        </label>
        <input
          {...register('amountSell')}
          type="number"
          step="any"
          id="amountSell"
          className="focus:ring-green-500 focus:border-green-500 block w-full px-2 sm:text-sm border-gray-400 rounded"
          placeholder="Enter tokens to sell amount"
        />
        {errors.amountSell ? (
          <span className="text-sm text-red-700">
            {errors.amountSell.message}
          </span>
        ) : null}
      </div>
      <div>
        <label htmlFor="sellPrice" className="text-sm">
          Sell Price
        </label>
        <input
          {...register('sellPrice')}
          type="number"
          step="any"
          id="sellPrice"
          className="focus:ring-green-500 focus:border-green-500 block w-full px-2 sm:text-sm border-gray-400 rounded"
          placeholder="Enter selling price"
        />
        {errors.sellPrice ? (
          <span className="text-sm text-red-700">
            {errors.sellPrice.message}
          </span>
        ) : null}
      </div>

      <button
        type="submit"
        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-palette-primary hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {openStoreMutation.isLoading ? <LoadingSpinner /> : null}
        Open Store
      </button>
    </form>
  );
};

export default OpenStoreFormModal;
