import React from 'react';
import { useQuery } from 'react-query';
import DataCard from '../../../app/components/common/DataCard';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { formatCurrency } from '../../../app/helpers/number';
import { fetchOrders, fetchOrderStats } from '../../../app/repositories/order';

const OrderListPage = () => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['orders'],
    fetchOrders
  );

  const {
    data: orderStatsData,
    isLoading: orderStatsIsLoading,
    isFetching: orderStatsIsFetching,
  } = useQuery('orderStats', fetchOrderStats);

  const orders = data?.data?.data?.orders;
  const stats = orderStatsData?.data?.data;

  if (isFetching || orderStatsIsFetching) {
    return <LoadingIndicator />;
  }

  return (
    <div className="flex flex-col">
      <div>
        <h2 className="text-2xl font-bold">Orders</h2>
      </div>
      <div className="my-4">
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
          <DataCard
            title="All-time"
            content={formatCurrency(stats.total.sum.amount)}
          />
          <DataCard
            title="Last 7 Days"
            content={formatCurrency(stats.last7Days.sum.amount)}
          />
          <DataCard
            title="Last 30 Days"
            content={formatCurrency(stats.last30Days.sum.amount)}
          />
        </dl>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Merchant
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Buyer
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Order Type
                  </th>
                  {/* <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th> */}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {orders.map((order) => (
                  <tr key={order.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="text-sm text-gray-500">{order.id}</div>
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {order.merchant.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {order.amount}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {order.user.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {order.status}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {order.option}
                    </td>
                    {/* <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-6">
                      <button className="text-indigo-600 hover:text-indigo-900">
                        Edit
                      </button>
                      <button className="text-red-600 hover:text-red-900">
                        Delete
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderListPage;
