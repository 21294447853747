import { ChevronDownIcon } from '@heroicons/react/solid';
import React, { useState, useEffect, useRef } from 'react';

export const DropdownWithSearch = ({ items = [], onChange, value }) => {
  const [options, setOptions] = useState(items);
  const [open, setOpen] = useState(false);
  const [term, setTerm] = useState('');
  const [selected, setSelected] = useState(value);
  const [refIndex, setRefIndex] = useState(
    options.findIndex((item) => item.value === selected)
  );
  const optionsRef = useRef([]);
  const containerRef = useRef();
  const dropdownRef = useRef();
  const searchRef = useRef();

  useEffect(() => {
    const container = containerRef.current;
    const searchInput = searchRef.current;
    const dropdown = dropdownRef.current;

    const onBodyClick = (e) => {
      if (container.contains(e.target)) {
        return;
      }
      setOpen(false);
    };

    const onDropdownKeyboardPress = (e) => {
      if (!open) {
        (e.key === 'ArrowDown' || e.key === 'ArrowUp') && setOpen(true);
      } else if (open) {
        e.key === 'Escape' && setOpen(false);

        if (e.key === 'ArrowUp') {
          if (refIndex > 0) {
            const selectedValue = options[refIndex - 1].value;
            setSelected(selectedValue);
            setRefIndex(
              options.findIndex((item) => item.value === selectedValue)
            );
          }
        } else if (e.key === 'ArrowDown') {
          if (refIndex < options.length - 1) {
            const selectedValue = options[refIndex + 1].value;
            setSelected(selectedValue);
            setRefIndex(
              options.findIndex((item) => item.value === selectedValue)
            );
          }
        } else if (e.key === 'Enter') {
          setTerm('');
          setOpen(false);
          dropdown.focus();
        }
      }
    };

    const onSearchInputKeyboardPress = (e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        setOpen(false);
        setTerm('');
        dropdown.focus();
      } else if (e.key === 'Escape') {
        setTerm('');
        dropdown.focus();
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (refIndex > 0) {
          const selectedValue = options[refIndex - 1].value;
          setSelected(selectedValue);
          setRefIndex(
            options.findIndex((item) => item.value === selectedValue)
          );
        }
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (refIndex < options.length - 1) {
          const selectedValue = options[refIndex + 1].value;
          setSelected(selectedValue);
          setRefIndex(
            options.findIndex((item) => item.value === selectedValue)
          );
        }
      }
    };

    document.body.addEventListener('click', onBodyClick);
    container.addEventListener('keydown', onDropdownKeyboardPress);
    searchInput.addEventListener('keydown', onSearchInputKeyboardPress);

    // optionsRef.current = optionsRef.current.slice(0, options.length);

    return () => {
      document.body.removeEventListener('click', onBodyClick);
      container.removeEventListener('keydown', onDropdownKeyboardPress);
      searchInput.removeEventListener('keydown', onSearchInputKeyboardPress);
    };
  }, [open, refIndex, options]);

  useEffect(() => {
    setRefIndex(options.findIndex((item) => item.value === selected));
  }, [options]);

  useEffect(() => {
    optionsRef.current[refIndex]?.scrollIntoView();
  }, [refIndex]);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  useEffect(() => {
    if (term === '') {
      setOptions(items);
    } else {
      setOptions(
        items.filter((item) =>
          item.name.toLowerCase().includes(term.toLowerCase())
        )
      );
    }
  }, [term]);

  return (
    <div ref={containerRef} className="relative">
      <div
        ref={dropdownRef}
        onClick={() => {
          setOpen(!open);
          optionsRef.current[refIndex]?.scrollIntoView();
        }}
        tabIndex={0}
        className="select-none cursor-default flex justify-between focus:ring-green-500 focus:border-green-500 focus:ring-1 focus:outline-none w-full p-2 sm:text-sm border-gray-400 border rounded"
      >
        <p>
          {selected &&
            (options.find((item) => item.value === selected)?.name ?? '')}
        </p>
        <ChevronDownIcon className="w-5 text-gray-500" />
      </div>
      <div
        className={`border border-gray-400 bg-white shadow-lg absolute w-full select-none mt-1 rounded z-50 ${
          open ? 'block' : 'hidden'
        }`}
      >
        <div className="p-2 border-b-2 sticky w-full">
          <input
            ref={searchRef}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            autoComplete="off"
            type="text"
            id="search"
            className="focus:ring-green-500 focus:border-green-500 block w-full px-2 sm:text-sm border-gray-400 bg-gray-100 rounded"
            placeholder="Search owner"
          />
        </div>
        <div className="overflow-scroll max-h-36">
          {options.length === 0 ? (
            <div
              className={`focus:ring-0 focus:outline-none w-full p-2 sm:text-sm border-gray-400 cursor-default`}
            >
              No item found
            </div>
          ) : (
            options.map((item, idx) => (
              <div
                onClick={() => {
                  setSelected(item.value);
                  setOpen(false);
                  setTerm('');
                  setRefIndex(
                    options.findIndex((item) => item.value === selected)
                  );
                }}
                ref={(el) => (optionsRef.current[idx] = el)}
                key={item.value}
                className={`focus:ring-0 focus:outline-none w-full p-2 sm:text-sm border-gray-400 hover:bg-gray-300 cursor-default ${
                  item.value === selected && 'bg-gray-300'
                }`}
              >
                {item.name}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
