import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ModalContext } from '../../contexts/modalContext';
import LoadingSpinner from '../common/LoadingSpinner';
import { editCurrency } from '../../repositories/currency';
import { ToastContext } from '../../contexts/toastContext';

const schema = yup.object().shape({
  value: yup
    .number()
    .required('Currency value cannot be empty')
    .positive('Please enter a valid currency')
    .typeError('Please enter a valid currency'),
});

const CurrencyFormModal = ({ currency }) => {
  const { hideModal } = useContext(ModalContext);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: currency, resolver: yupResolver(schema) });

  const editMutation = useMutation(
    (formValues) => editCurrency(currency.id, formValues),
    {
      onError: (error) => {
        showToast(
          'ERROR',
          'Failed to Update Currency',
          `${error.response?.data?.message || error.message}`
        );
      },
      onSuccess: () => {
        showToast('SUCCESS', 'Success', 'Currency value updated successfully');
        queryClient.invalidateQueries('currencies');
        hideModal();
      },
    }
  );

  const onSubmit = (formValues) => {
    editMutation.mutate(formValues);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex-1 flex flex-col px-2 py-3 space-y-6"
    >
      <div>
        <label htmlFor="value" className="text-sm">
          Value
        </label>
        <input
          {...register('value')}
          type="number"
          step="any"
          id="value"
          className="focus:ring-green-500 focus:border-green-500 block w-full px-2 sm:text-sm border-gray-400 rounded"
          placeholder="Enter currency value"
        />
        {errors.value ? (
          <span className="text-sm text-red-700">{errors.value.message}</span>
        ) : null}
      </div>

      <button
        type="submit"
        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-palette-primary hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {editMutation.isLoading ? <LoadingSpinner /> : null}
        Save
      </button>
    </form>
  );
};

export default CurrencyFormModal;
