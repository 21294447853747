import { api } from '../api/api';

export const fetchNotifications = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');
  return api.get(`/notifications`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};
