import { isBefore } from 'date-fns/esm';
import React, { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DataCard from '../../../app/components/common/DataCard';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { DialogContext } from '../../../app/contexts/dialogContext';
import { ModalContext } from '../../../app/contexts/modalContext';
import { ToastContext } from '../../../app/contexts/toastContext';
import {
  formatDateWithoutYear,
  formatFullDate,
} from '../../../app/helpers/datetime';
import {
  acceptRedeemAppointment,
  confirmRedeemAppointment,
  declineRedeemAppointment,
  fetchRedeemAppointments,
} from '../../../app/repositories/token';
import {
  editWithdrawalRequest,
  fetchWithdrawalRequests,
} from '../../../app/repositories/withdrawal';

export const WithdrawalListPage = () => {
  const { showModal } = useContext(ModalContext);
  const { showDialog } = useContext(DialogContext);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['withdrawals'],
    fetchWithdrawalRequests
  );

  const completeMutation = useMutation(
    (id) => editWithdrawalRequest(id, 'COMPLETED'),
    {
      onError: (error) => {
        showToast(
          'ERROR',
          'Failed to Complete Withdrawal Request',
          `${error.response?.data?.message || error.message}`
        );
      },
      onSuccess: () => {
        showToast(
          'SUCCESS',
          'Success',
          'The withdrawal has been completed successfully'
        );
        queryClient.invalidateQueries('withdrawals');
      },
    }
  );

  const rejectMutation = useMutation(
    (id) => editWithdrawalRequest(id, 'FAILED'),
    {
      onError: (error) => {
        showToast(
          'ERROR',
          'Failed to Reject Withdrawal Request',
          `${error.response?.data?.message || error.message}`
        );
      },
      onSuccess: () => {
        showToast(
          'SUCCESS',
          'Success',
          'The withdrawal has been rejected successfully'
        );
        queryClient.invalidateQueries('withdrawals');
      },
    }
  );

  const requests = data?.data?.data?.requests;

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="flex flex-col">
      <div>
        <h2 className="text-2xl font-bold">Withdrawal Requests</h2>
      </div>
      <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Bank Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Bank Account Number
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Withdraw Amount (RM)
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Current Balance (RM)
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {requests.map((request) => (
                  <tr key={request.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="text-sm font-medium text-gray-900">
                          {request?.bankInformation?.personalIdentity?.fullname}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {request?.bankInformation?.bankName}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {request?.bankInformation?.bankAccountNumber}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {request?.amount}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {Number(request?.user?.balance).toFixed(2)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-3">
                      {request?.status === 'PENDING' ? (
                        <>
                          <button
                            onClick={() => {
                              completeMutation.mutate(request.id);
                            }}
                            className="text-green-600 hover:text-green-900"
                          >
                            Confirm Withdrawal
                          </button>
                          <button
                            onClick={() => {
                              rejectMutation.mutate(request.id);
                            }}
                            className="text-red-600 hover:text-red-900"
                          >
                            Reject Withdrawal
                          </button>
                        </>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
