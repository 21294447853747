import { useContext } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { AppointmentListPage } from '../features/appointments/pages/AppointmentListPage';
import { LoginPage } from '../features/auth/pages/LoginPage';
import { UserBankInfoListPage } from '../features/bank-information/pages/UserBankInfoListPage';
import { PotentialTokenBuyerListPage } from '../features/buyers/pages/PotentialTokenBuyerListPage';
import { CategoryListPage } from '../features/categories/pages/CategoryListPage';
import { CurrencyListPage } from '../features/currencies/pages/CurrencyListPage';
import { CustodyListPage } from '../features/custodies/pages/CustodyListPage';
import DashboardPage from '../features/dashboard/screens/DashboardPage';
import OrderListPage from '../features/orders/pages/OrderListPage';
import { TokenStoreListPage } from '../features/tokens/pages/TokenStoreListPage';
import { UserListPage } from '../features/users/pages/UserListPage';
import { WithdrawalListPage } from '../features/withdrawals/pages/WithdrawalListPage';
import DialogWrapper from './components/common/DialogWrapper';
import ModalWrapper from './components/common/ModalWrapper';
import { ProtectedRoute } from './components/common/ProtectedRoute';
import { Toast } from './components/common/Toast';
import { AuthContext } from './contexts/authContext';
import history from './helpers/history';
import DashboardLayout from './layouts/DashboardLayout';

function App() {
  let { from } = history.location.state || { from: { pathname: '/' } };
  const { state } = useContext(AuthContext);

  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" exact>
          {state.isLoggedIn ? <Redirect to={from} /> : <LoginPage />}
        </Route>
        {/* <Route path="/forgot-password" exact>
          <ForgotPasswordPage />
        </Route>
        <Route path="/reset-password" exact>
          <ResetPasswordPage />
        </Route> */}
        <ProtectedRoute path="/" exact>
          <Redirect to="/dashboard" />
        </ProtectedRoute>
        <ProtectedRoute path="/dashboard" exact>
          <DashboardLayout>
            <DashboardPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/tokens" exact>
          <DashboardLayout>
            <TokenStoreListPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/buyers" exact>
          <DashboardLayout>
            <PotentialTokenBuyerListPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/custodies" exact>
          <DashboardLayout>
            <CustodyListPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/appointments" exact>
          <DashboardLayout>
            <AppointmentListPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/withdrawal-requests" exact>
          <DashboardLayout>
            <WithdrawalListPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/users" exact>
          <DashboardLayout>
            <UserListPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/bank-information" exact>
          <DashboardLayout>
            <UserBankInfoListPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/categories" exact>
          <DashboardLayout>
            <CategoryListPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/orders" exact>
          <DashboardLayout>
            <OrderListPage />
          </DashboardLayout>
        </ProtectedRoute>
        <ProtectedRoute path="/currencies" exact>
          <DashboardLayout>
            <CurrencyListPage />
          </DashboardLayout>
        </ProtectedRoute>
        {/* <ProtectedRoute path="/profile" exact>
          <DashboardLayout>
            <div>Hello profile</div>
          </DashboardLayout>
        </ProtectedRoute> */}
      </Switch>
      <div className="fixed z-10 items-center sm:items-end pointer-events-none justify-end sm:justify-start inset-0 my-6 mx-4 space-y-3 flex flex-col">
        <Toast />
      </div>
      <ModalWrapper />
      <DialogWrapper />
    </Router>
  );
}

export default App;
