export const formatCurrency = (data) => {
  return new Intl.NumberFormat('my-MY', {
    style: 'currency',
    currency: 'MYR',
  }).format(data);
};

export const formatNumber = (data) => {
  return new Intl.NumberFormat('en-MY', {
    style: 'decimal',
  }).format(data);
};
