import { api } from '../api/api';

export const fetchCurrencies = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/currencies`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const editCurrency = async (id, formValues) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.patch(`/currencies/${id}`, formValues, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};
