import React, { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import {
  fetchBankInformation,
  fetchUsers,
  verifyBankInformation,
} from '../../../app/repositories/user';
import { DialogContext } from '../../../app/contexts/dialogContext';
import { ModalContext } from '../../../app/contexts/modalContext';
import { ToastContext } from '../../../app/contexts/toastContext';

export const UserBankInfoListPage = () => {
  const { showModal } = useContext(ModalContext);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();

  const verifyMutation = useMutation((id) => verifyBankInformation(id), {
    onError: (error) => {
      showToast(
        'ERROR',
        'Failed to Verify Bank Information',
        `${error.response?.data?.message || error.message}`
      );
    },
    onSuccess: () => {
      showToast('SUCCESS', 'Success', 'Bank information verified');
      queryClient.invalidateQueries('bankInfo');
    },
  });

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['bankInfo'],
    fetchBankInformation
  );

  const bankInformation = data?.data?.data?.bankInformation;

  if (isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="mb-4">
          <h2 className="text-2xl font-bold">Bank Information</h2>
          {/* <button className="bg-palette-primary px-4 py-2 my-4 text-white rounded-lg shadow-lg hover:bg-blue-600">
            New User
          </button> */}
        </div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Bank Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Bank Account Number
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {bankInformation.map((info) => (
                    <tr key={info.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {info?.personalIdentity?.fullname}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {info?.bankName}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {info?.bankAccountNumber}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-3">
                        {!info?.isVerified ? (
                          <button
                            onClick={() => verifyMutation.mutate(info.id)}
                            className="text-green-600 hover:text-green-900"
                          >
                            Approve
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
