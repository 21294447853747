import { api } from '../api/api';

export const fetchTokenStores = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/tokens/stores`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const fetchAurachnidStore = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/tokens/stores/admin`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const openTokenStore = async (formValues) => {
  const { amountBuy, amountSell, buyPrice, sellPrice } = formValues;
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.post(
    '/wakalah/store/open',
    {
      amountBuy,
      amountSell,
      buyPrice,
      sellPrice,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const closeTokenStore = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.post(
    '/wakalah/store/close',
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const fetchPotentialTokenBuyers = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/tokens/shortlist`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const fetchRedeemAppointments = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/tokens/appointments`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const acceptRedeemAppointment = async (id) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.post(
    `/tokens/appointments/${id}/accept`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
};

export const declineRedeemAppointment = async (id) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.post(
    `/tokens/appointments/${id}/decline`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
};

export const confirmRedeemAppointment = async (id) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.post(
    `/tokens/appointments/${id}/confirm`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
};
