import React, { createContext, useReducer } from 'react';

export const DialogContext = createContext({});

const initialState = {
  show: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ShowDialog':
      return {
        show: true,
        type: action.payload.type,
        title: action.payload.title,
        content: action.payload.content,
        actionText: action.payload.actionText,
        onActionPress: action.payload.onActionPress,
        props: action.payload.props,
      };
    case 'HideDialog':
      return { ...state, show: false };
    default:
      return state;
  }
};

export const DialogContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showDialog = ({
    type,
    title,
    content,
    actionText,
    onActionPress,
    props,
  }) => {
    dispatch({
      type: 'ShowDialog',
      payload: {
        type,
        title,
        content,
        actionText,
        onActionPress,
        props,
      },
    });
  };

  const hideDialog = () => {
    dispatch({ type: 'HideDialog' });
  };

  return (
    <DialogContext.Provider value={{ state, hideDialog, showDialog }}>
      {props.children}
    </DialogContext.Provider>
  );
};
