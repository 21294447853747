import { isBefore } from 'date-fns/esm';
import React, { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DataCard from '../../../app/components/common/DataCard';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { DialogContext } from '../../../app/contexts/dialogContext';
import { ModalContext } from '../../../app/contexts/modalContext';
import { ToastContext } from '../../../app/contexts/toastContext';
import {
  formatDateWithoutYear,
  formatFullDate,
} from '../../../app/helpers/datetime';
import {
  acceptRedeemAppointment,
  confirmRedeemAppointment,
  declineRedeemAppointment,
  fetchRedeemAppointments,
} from '../../../app/repositories/token';

export const AppointmentListPage = () => {
  const { showModal } = useContext(ModalContext);
  const { showDialog } = useContext(DialogContext);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['custodies', 'appointments'],
    fetchRedeemAppointments
  );

  const acceptMutation = useMutation((id) => acceptRedeemAppointment(id), {
    onError: (error) => {
      showToast(
        'ERROR',
        'Failed to Accept Appointment',
        `${error.response?.data?.message || error.message}`
      );
    },
    onSuccess: () => {
      showToast(
        'SUCCESS',
        'Success',
        'The appointment has been accepted successfully'
      );
      queryClient.invalidateQueries('custodies');
    },
  });

  const declineMutation = useMutation((id) => declineRedeemAppointment(id), {
    onError: (error) => {
      showToast(
        'ERROR',
        'Failed to Decline Appointment',
        `${error.response?.data?.message || error.message}`
      );
    },
    onSuccess: () => {
      showToast(
        'SUCCESS',
        'Success',
        'The appointment has been declined successfully'
      );
      queryClient.invalidateQueries('custodies');
    },
  });

  const confirmMutation = useMutation((id) => confirmRedeemAppointment(id), {
    onError: (error) => {
      showToast(
        'ERROR',
        'Failed to Confirm Redeem',
        `${error.response?.data?.message || error.message}`
      );
    },
    onSuccess: () => {
      showToast(
        'SUCCESS',
        'Success',
        'The token has been reedemed successfully'
      );
      queryClient.invalidateQueries('custodies');
    },
  });

  const appointments = data?.data?.data?.appointments;

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="flex flex-col">
      <div>
        <h2 className="text-2xl font-bold">Appointments</h2>
      </div>
      <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Phone Number
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Appointment Date
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {appointments.map((appointment) => (
                  <tr key={appointment.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="text-sm font-medium text-gray-900">
                          {appointment?.user?.name}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {appointment?.user?.email}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {appointment?.user?.contactNumber}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {!!appointment.date
                          ? formatFullDate(appointment.date)
                          : '-'}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-3">
                      {appointment?.status ===
                      'FINISHED' ? null : appointment?.status ===
                        'REQUESTED' ? (
                        <>
                          <button
                            onClick={() => {
                              acceptMutation.mutate(appointment.id);
                            }}
                            className="text-green-600 hover:text-green-900"
                          >
                            Accept
                          </button>
                          <button
                            onClick={() => {
                              declineMutation.mutate(appointment.id);
                            }}
                            className="text-red-600 hover:text-red-900"
                          >
                            Decline
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={() => {
                            confirmMutation.mutate(appointment.id);
                          }}
                          className="text-green-600 hover:text-green-900"
                        >
                          Confirm Redeem
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
