import { api } from '../api/api';

export const fetchDashboardStats = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/admin/stats`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};
