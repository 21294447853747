import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './app/App';
import { AuthContextProvider } from './app/contexts/authContext';
import { DialogContextProvider } from './app/contexts/dialogContext';
import { ModalContextProvider } from './app/contexts/modalContext';
import { ToastContextProvider } from './app/contexts/toastContext';
import './index.css';

const queryClient = new QueryClient();

ReactDOM.render(
  <AuthContextProvider>
    <ToastContextProvider>
      <ModalContextProvider>
        <DialogContextProvider>
          <QueryClientProvider client={queryClient}>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </QueryClientProvider>
        </DialogContextProvider>
      </ModalContextProvider>
    </ToastContextProvider>
  </AuthContextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
