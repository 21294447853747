import { isBefore } from 'date-fns/esm';
import React, { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DataCard from '../../../app/components/common/DataCard';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { DialogContext } from '../../../app/contexts/dialogContext';
import { ModalContext } from '../../../app/contexts/modalContext';
import { ToastContext } from '../../../app/contexts/toastContext';
import {
  formatDateWithoutYear,
  formatFullDate,
} from '../../../app/helpers/datetime';
import {
  fetchCustodies,
  fetchCustodyStats,
  retireCustody,
} from '../../../app/repositories/custody';
import {
  closeTokenStore,
  fetchAurachnidStore,
  fetchTokenStores,
} from '../../../app/repositories/token';

export const TokenStoreListPage = () => {
  const { showModal } = useContext(ModalContext);
  const { showDialog } = useContext(DialogContext);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching, refetch } = useQuery('tokenStores', () =>
    fetchTokenStores()
  );

  const { data: adminStoreData, isLoading: adminStoreIsLoading } = useQuery(
    ['tokenStores', 'admin'],
    fetchAurachnidStore
  );

  const tokenStores = data?.data.data.tokenStores;
  const adminStore = adminStoreData?.data.data.tokenStore;

  const closeStoreMutation = useMutation((id) => closeTokenStore(), {
    onError: (error) => {
      showToast(
        'ERROR',
        'Failed to close store',
        `${error.response?.data?.message || error.message}`
      );
    },
    onSuccess: () => {
      showToast('SUCCESS', 'Success', 'The store is now closed');
      queryClient.invalidateQueries('tokenStores');
    },
  });

  if (isFetching || isLoading || adminStoreIsLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="flex flex-col">
      <div>
        <h2 className="text-2xl font-bold">Token Store</h2>
        {adminStore?.amountBuy || adminStore?.amountSell ? (
          <button
            disabled={closeStoreMutation.isLoading}
            onClick={() => {
              closeStoreMutation.mutate();
            }}
            className="bg-palette-secondary px-4 py-2 my-4 text-white rounded-lg shadow-lg hover:bg-yellow-600"
          >
            Close Store
          </button>
        ) : (
          <button
            onClick={() => showModal('Open Token Store', 'OpenStore')}
            className="bg-palette-primary px-4 py-2 my-4 text-white rounded-lg shadow-lg hover:bg-green-600"
          >
            Open Store
          </button>
        )}
      </div>
      {adminStore?.amountBuy || adminStore?.amountSell ? (
        <div className="bg-white p-4 mb-4">
          <h2 className="text-xl font-semibold mb-4">Token Store is Open</h2>
          <div className="flex flex-row justify-between">
            <div className="flex-1 text-center">
              <h2 className="text-xl font-semibold">Amount Sell</h2>
              <h2 className="mb-2">{adminStore?.amountSell}</h2>
              <h2 className="text-xl font-semibold">Price</h2>
              <h2>RM {adminStore?.sellPrice}</h2>
            </div>
            <div className="flex-1 text-center">
              <h2 className="text-xl font-semibold">Amount Buy</h2>
              <h2 className="mb-2">{adminStore?.amountBuy}</h2>
              <h2 className="text-xl font-semibold">Price</h2>
              <h2>RM {adminStore?.buyPrice}</h2>
            </div>
          </div>
        </div>
      ) : null}
      {/* <div className="mb-4">
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
          <DataCard
            title="Total Token"
            content={stats?.total.sum.tokenAmount ?? 0}
          />
        </dl>
      </div> */}
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Seller
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    No. of Token
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Opened Since
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {tokenStores.map((store) => (
                  <tr key={store.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {store?.user?.name}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {store?.amountSell}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {store?.sellPrice}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {formatFullDate(store?.updatedAt)}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
