import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { DialogContext } from '../../../app/contexts/dialogContext';
import { ModalContext } from '../../../app/contexts/modalContext';
import { formatFullDate } from '../../../app/helpers/datetime';
import { fetchCategories } from '../../../app/repositories/category';

export const CategoryListPage = () => {
  const { showModal } = useContext(ModalContext);
  const { showDialog } = useContext(DialogContext);

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['categories'],
    fetchCategories
  );

  const categories = data?.data?.data?.categories;

  if (isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <div className="flex flex-col">
      <div>
        <h2 className="text-2xl font-bold">Categories</h2>
        <button
          onClick={() => showModal('Create New Category', 'AddCategory')}
          className="bg-palette-primary px-4 py-2 my-4 text-white rounded-lg shadow-lg hover:bg-green-600"
        >
          New Category
        </button>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Updated at
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {categories.map((category) => (
                  <tr key={category.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="text-sm text-gray-500">
                          {category.name}
                        </div>
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatFullDate(category.updatedAt)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-6">
                      <button
                        onClick={() =>
                          showModal(`Edit Category`, 'AddCategory', {
                            values: category,
                          })
                        }
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => {
                          showDialog({
                            type: 'DANGER',
                            title: 'Delete Category',
                            content:
                              'Are you sure you want to delete this category?',
                            actionText: 'Delete',
                            onActionPress: () => console.log('Deleted'),
                          });
                        }}
                        className="text-red-600 hover:text-red-900"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
