import { api } from '../api/api';

export const fetchWithdrawalRequests = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/withdrawal-requests`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const editWithdrawalRequest = async (id, status) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.patch(
    `/withdrawal-requests/${id}`,
    { status },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
};
