/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react';
import {
  DotsCircleHorizontalIcon,
  HomeIcon,
  ViewBoardsIcon,
  MenuIcon,
  CurrencyDollarIcon,
  XIcon,
  UserGroupIcon,
  InboxInIcon,
  ShoppingCartIcon,
  CalculatorIcon,
  HandIcon,
  ArrowDownIcon,
  CreditCardIcon,
} from '@heroicons/react/outline';
import React, { Fragment, useRef, useState, useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';
import { BellIcon } from '@heroicons/react/outline';
import logo from '../assets/images/mudarabah-logo.png';
import history from '../helpers/history';
import { fetchUserProfile } from '../repositories/user';
import { fetchNotifications } from '../repositories/notification';
import EmptyState from '../components/common/EmptyState';
import { approveBuybackCustody } from '../repositories/custody';
import { ToastContext } from '../contexts/toastContext';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { AuthContext } from '../contexts/authContext';

const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: true },
  {
    name: 'Tokens',
    href: '/tokens',
    icon: DotsCircleHorizontalIcon,
    current: false,
  },
  {
    name: 'Potential Buyers',
    href: '/buyers',
    icon: CalculatorIcon,
    current: false,
  },
  {
    name: 'Custodies',
    href: '/custodies',
    icon: InboxInIcon,
    current: false,
  },
  {
    name: 'Appointments',
    href: '/appointments',
    icon: HandIcon,
    current: false,
  },
  {
    name: 'Withdrawal Requests',
    href: '/withdrawal-requests',
    icon: ArrowDownIcon,
    current: false,
  },
  {
    name: 'Users',
    href: '/users',
    icon: UserGroupIcon,
    current: false,
  },
  {
    name: 'Bank Information',
    href: '/bank-information',
    icon: CreditCardIcon,
    current: false,
  },
  {
    name: 'Orders',
    href: '/orders',
    icon: ShoppingCartIcon,
    current: false,
  },
  {
    name: 'Categories',
    href: '/categories',
    icon: ViewBoardsIcon,
    current: false,
  },
  {
    name: 'Currencies',
    href: '/currencies',
    icon: CurrencyDollarIcon,
    current: false,
  },
];

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

const DashboardLayout = ({ children, title, ...rest }) => {
  const queryClient = useQueryClient();
  const { deleteToken } = useContext(AuthContext);
  const closeNotifDrawerRef = useRef();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notificationSidebarOpen, setNotificationSidebarOpen] = useState(false);
  const { data, isLoading, isFetching, refetch } = useQuery(
    'profile',
    fetchUserProfile,
    { refetchOnWindowFocus: false }
  );
  const {
    data: notificationData,
    isLoading: notificationIsLoading,
    isFetching: notificationIsFetch,
  } = useQuery('notifications', fetchNotifications, {
    refetchOnWindowFocus: false,
  });

  const profile = data?.data.data.profile;
  const notifications = notificationData?.data.data.notifications;

  return (
    <>
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 flex z-40 md:hidden"
            open={sidebarOpen}
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img className="w-auto mx-auto" src={logo} alt="Workflow" />
                  </div>
                  <nav className="mt-5 px-2 space-y-1">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className="hover:bg-palette-primary hover:text-white group flex items-center px-2 py-2 text-md font-medium rounded-md"
                        activeClassName="bg-palette-primary text-white"
                      >
                        <item.icon
                          className="mr-3 h-6 w-6 text-palette-secondary"
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    ))}
                  </nav>
                </div>
                <div className="flex-shrink-0 justify-between items-center flex border-t border-gray-200 p-4">
                  <div className="flex-shrink-0 group block">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-10 w-10 rounded-full"
                          src={profile?.avatar}
                          alt={`${profile?.name} profile`}
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                          {profile?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      sidebarOpen && setSidebarOpen(false);
                      setNotificationSidebarOpen(true);
                    }}
                  >
                    <BellIcon className="w-6 text-gray-500" />
                    {!profile?.unreadNotifications ? null : (
                      <div className="bg-red-700 text-white rounded-full right-0">
                        <p className="text-2xs">
                          {profile.unreadNotifications}
                        </p>
                      </div>
                    )}
                  </button>
                </div>
                <div className="flex px-4 pb-3 justify-center">
                  <button
                    onClick={() => {
                      deleteToken();
                      queryClient.clear();
                      history.push('/login');
                    }}
                    className="flex-1 py-2 bg-palette-secondary rounded-md text-yellow-800"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:flex-shrink-0">
          <div className="flex flex-col w-64">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white">
              <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                <div className="flex items-center flex-shrink-0 px-4">
                  <img className="w-auto mx-auto" src={logo} alt="Workflow" />
                </div>
                <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
                  {navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className="hover:bg-palette-primary hover:text-white group flex items-center px-2 py-2 text-md font-medium rounded-md"
                      activeClassName="bg-palette-primary text-white"
                    >
                      <item.icon
                        className="mr-3 h-6 w-6 text-palette-secondary"
                        aria-hidden="true"
                      />
                      {item.name}
                    </NavLink>
                  ))}
                </nav>
              </div>
              {isLoading ? null : (
                <>
                  <div className="flex-shrink-0 justify-between items-center flex border-t border-gray-200 p-4">
                    <div className="flex-shrink-0 group block">
                      <div className="flex items-center">
                        <div>
                          <img
                            className="inline-block h-9 w-9 rounded-full"
                            src={profile?.avatar}
                            alt={`${profile?.name} profile`}
                          />
                        </div>
                        <div className="ml-3 flex flex-col items-start">
                          <p className="flex text-sm font-medium text-gray-700 group-hover:text-gray-900">
                            {profile?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                    <button onClick={() => setNotificationSidebarOpen(true)}>
                      <BellIcon className="w-6 text-gray-500" />
                      {!profile?.unreadNotifications ? null : (
                        <div className="bg-red-700 text-white rounded-full right-0">
                          <p className="text-2xs">
                            {profile.unreadNotifications}
                          </p>
                        </div>
                      )}
                    </button>
                  </div>
                  <div className="flex px-4 pb-3 justify-center">
                    <button
                      onClick={() => {
                        deleteToken();
                        queryClient.clear();
                        history.push('/login');
                      }}
                      className="flex-1 py-2 bg-palette-secondary rounded-md text-yellow-800"
                    >
                      Logout
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
            <button
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6">
              {!title ? null : (
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 md:mb-4 mb-2">
                  <h1 className="text-2xl font-semibold text-gray-900">
                    {title}
                  </h1>
                </div>
              )}
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {/* Replace with your content */}
                {React.cloneElement(children, { ...rest })}
                {/* /End replace */}
              </div>
            </div>
          </main>
        </div>
        <Transition.Root show={notificationSidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            open={notificationSidebarOpen}
            onClose={setNotificationSidebarOpen}
            initialFocus={closeNotifDrawerRef}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-full sm:w-96 z-50 bg-white absolute right-0 top-0 h-screen overflow-scroll">
                <div className="p-4 md:px-6 flex items-center justify-between fixed bg-white w-full sm:w-96 border-b">
                  <h2 className="text-xl font-bold">Notifications</h2>
                  <button
                    ref={closeNotifDrawerRef}
                    className="ml-1 flex items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-700 p-1"
                    onClick={() => setNotificationSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon
                      className="h-6 w-6 text-gray-700"
                      aria-hidden="true"
                    />
                  </button>
                </div>

                <div className="overflow-y-scroll pt-16 h-full">
                  {notifications?.length === 0 ? (
                    <div className="flex items-center justify-center h-full px-8">
                      <EmptyState message="No notifications" />
                    </div>
                  ) : (
                    notifications?.map((notification) => {
                      return (
                        <NotificationItem
                          key={notification.id}
                          notification={notification}
                        />
                      );
                    })
                  )}
                </div>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
};

const NotificationItem = ({ notification }) => {
  const mutation = useMutation((id) => approveBuybackCustody(id));

  const renderAction = () => {
    if (notification.type === 'CUSTODY_BUYBACK_REQUEST') {
      return (
        <button
          onClick={() => mutation.mutate(notification.payload.id)}
          className="w-full mt-2 py-2 bg-palette-secondary rounded-md text-yellow-800"
        >
          Approve
        </button>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="text-left px-6 py-4 border-b">
      <h4 className="font-semibold">{notification.title}</h4>
      <p className="text-sm">{notification.body}</p>
      {renderAction()}
    </div>
  );
};

export default DashboardLayout;
