import { api } from '../api/api';

export const fetchCategories = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/categories`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const addCategory = async (formValues) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.post('/categories', formValues, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const editCategory = async (id, formValues) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.patch(`/categories/${id}`, formValues, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const deleteCategory = async (id) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.delete(`/categories/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};
