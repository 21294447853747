import { isBefore } from 'date-fns/esm';
import React, { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import DataCard from '../../../app/components/common/DataCard';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { DialogContext } from '../../../app/contexts/dialogContext';
import { ModalContext } from '../../../app/contexts/modalContext';
import { ToastContext } from '../../../app/contexts/toastContext';
import {
  formatDateWithoutYear,
  formatFullDate,
} from '../../../app/helpers/datetime';
import {
  fetchCustodies,
  fetchCustodyStats,
  retireCustody,
} from '../../../app/repositories/custody';

export const CustodyListPage = () => {
  const { showModal } = useContext(ModalContext);
  const { showDialog } = useContext(DialogContext);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['custodies'],
    fetchCustodies
  );

  const mutation = useMutation((id) => retireCustody(id), {
    onError: (error) => {
      showToast(
        'ERROR',
        'Failed to Retire Custody',
        `${error.response?.data?.message || error.message}`
      );
    },
    onSuccess: () => {
      showToast(
        'SUCCESS',
        'Success',
        'The custody has been retired successfully'
      );
      queryClient.invalidateQueries('custodies');
      queryClient.invalidateQueries('custodyStats');
    },
  });

  const {
    data: custodyStatsData,
    isLoading: custodyStatsIsLoading,
    isFetching: custodyStatsIsFetching,
  } = useQuery('custodyStats', fetchCustodyStats);

  const custodies = data?.data?.data?.custodies;
  const stats = custodyStatsData?.data?.data;

  if (isFetching || custodyStatsIsFetching) {
    return <LoadingIndicator />;
  }

  return (
    <div className="flex flex-col">
      <div>
        <h2 className="text-2xl font-bold">Custodies</h2>
        <button
          onClick={() => showModal('Create New Custody', 'AddCustody')}
          className="bg-palette-primary px-4 py-2 my-4 text-white rounded-lg shadow-lg hover:bg-green-600"
        >
          New Custody
        </button>
      </div>
      <div className="mb-4">
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
          <DataCard
            title="Total Active Token"
            content={stats?.total.sum.tokenAmount ?? 0}
          />
        </dl>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Jewelry
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    No. of Token
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Maturity Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Buyback Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Tag
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Owner
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Hash
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {custodies.map((custody) => (
                  <tr key={custody.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div>
                          <div className="text-sm font-medium text-gray-900">
                            {custody.jewelryName}
                          </div>
                          <div className="text-sm text-gray-500">
                            {custody.jewelryWeight} gr
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {custody.tokenAmount}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {formatDateWithoutYear(custody.maturityDate)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {!!custody.buybackDate
                          ? formatDateWithoutYear(custody.buybackDate)
                          : '-'}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{custody.tag}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {custody.owner.name}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {custody.blockchainHash}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      {custody.buybackDate ? null : isBefore(
                          new Date(),
                          new Date(custody.maturityDate)
                        ) ? (
                        <button
                          // 03/01/95 before 02/01/95
                          onClick={() => {
                            showDialog({
                              type: 'DANGER',
                              title: 'Delete Custody',
                              content: `Are you sure you want to retire ${custody.tag}? This action cannot be undone.`,
                              actionText: 'Confirm',
                              onActionPress: () => mutation.mutate(custody.id),
                            });
                          }}
                          className="text-red-600 hover:text-red-900"
                        >
                          Retire
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            showDialog({
                              type: 'DANGER',
                              title: 'Purge Custody',
                              content: `Are you sure you want to purge ${custody.tag} from the system? This action cannot be undone.`,
                              actionText: 'Confirm',
                              onActionPress: () => mutation.mutate(custody.id),
                            });
                          }}
                          className="text-red-600 hover:text-red-900"
                        >
                          Purge
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
