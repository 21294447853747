import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { LoadingIndicator } from '../../../app/components/common/LoadingIndicator';
import { fetchUsers } from '../../../app/repositories/user';
import { DialogContext } from '../../../app/contexts/dialogContext';
import { ModalContext } from '../../../app/contexts/modalContext';

export const UserListPage = () => {
  const { showDialog } = useContext(DialogContext);
  const { showModal } = useContext(ModalContext);

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['users'],
    fetchUsers
  );

  const users = data?.data?.data?.users;

  if (isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="mb-4">
          <h2 className="text-2xl font-bold">Users</h2>
          {/* <button className="bg-palette-primary px-4 py-2 my-4 text-white rounded-lg shadow-lg hover:bg-blue-600">
            New User
          </button> */}
        </div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Phone Number
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Role
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {users.map((user) => (
                    <tr key={user.email}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <img
                              className="h-10 w-10 rounded-full"
                              src={user.avatar}
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {user.name}
                            </div>
                            <div className="text-sm text-gray-500">
                              {user.email}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {user.contactNumber}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            user?.personalIdentity &&
                            user?.personalIdentity?.isVerified
                              ? 'bg-green-100 text-green-800'
                              : 'bg-red-100 text-red-800'
                          }`}
                        >
                          {user?.personalIdentity &&
                          user?.personalIdentity?.isVerified
                            ? 'Verified'
                            : 'Not Verified'}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.role}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-3">
                        {user?.personalIdentity &&
                        !user?.personalIdentity?.isVerified ? (
                          <button
                            onClick={() =>
                              showModal(
                                'Verify User Identity',
                                'VerifyUserIdentity',
                                { user }
                              )
                            }
                            className="text-blue-600 hover:text-blue-900"
                          >
                            Verify Identity
                          </button>
                        ) : null}
                        <button
                          onClick={() => {
                            showDialog({
                              type: 'DANGER',
                              title: 'Delete User',
                              content:
                                'Are you sure you want to delete this user? This action cannot be undone.',
                              actionText: 'Confirm',
                              onActionPress: () => console.log('Deleted'),
                            });
                          }}
                          className="text-red-600 hover:text-red-900"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
