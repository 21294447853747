import { api } from '../api/api';

export const fetchOrders = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/orders`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const fetchOrderStats = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/orders/stats`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};
