import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { SelectorIcon } from '@heroicons/react/solid';
import React, { Fragment, useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ModalContext } from '../../contexts/modalContext';
import { ToastContext } from '../../contexts/toastContext';
import { addCustody } from '../../repositories/custody';
import { fetchWakalahs } from '../../repositories/user';
import { DropdownWithSearch } from '../common/DropdownWithSearch';
import { LoadingIndicator } from '../common/LoadingIndicator';
import LoadingSpinner from '../common/LoadingSpinner';

const CustodyFormModal = () => {
  const { hideModal } = useContext(ModalContext);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const mutation = useMutation((formValues) => addCustody(formValues), {
    onError: (error) => {
      showToast(
        'ERROR',
        'Failed to Create New Custody',
        `${error.response?.data?.message || error.message}`
      );
    },
    onSuccess: () => {
      showToast('SUCCESS', 'Success', 'Custody created successfully');
      queryClient.invalidateQueries('custodies');
      queryClient.invalidateQueries('custodyStats');
      hideModal();
    },
  });

  const onSubmit = (formValues) => {
    mutation.mutate(formValues);
  };

  const { data, isLoading, isFetching, refetch } = useQuery(
    'wakalahs',
    fetchWakalahs,
    { refetchOnWindowFocus: false }
  );

  const wakalahs = data?.data?.data?.users;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col px-2 py-3 space-y-3"
    >
      <div>
        <label htmlFor="jewelryName" className="text-sm">
          Jewelry Name
        </label>
        <input
          {...register('jewelryName', { required: true })}
          type="text"
          id="jewelryName"
          className="focus:ring-green-500 focus:border-green-500 block w-full px-2 sm:text-sm border-gray-400 rounded"
          placeholder="Enter jewelry name"
        />
        {errors.jewelryName ? (
          <span className="text-sm text-red-700">This field is required</span>
        ) : null}
      </div>

      <div>
        <label htmlFor="jewelryWeight" className="text-sm">
          Jewelry Weight (gram)
        </label>
        <input
          {...register('jewelryWeight', { required: true })}
          type="number"
          step="any"
          id="jewelryWeight"
          className="focus:ring-green-500 focus:border-green-500 block w-full px-2 sm:text-sm border-gray-400 rounded"
          placeholder="Enter jewelry weight"
        />
        {errors.jewelryWeight ? (
          <span className="text-sm text-red-700">This field is required</span>
        ) : null}
      </div>

      <div>
        <label htmlFor="ownerId" className="text-sm">
          Owner Name
        </label>
        {isFetching ? null : (
          <Controller
            name="ownerId"
            id="ownerId"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <DropdownWithSearch
                onChange={onChange}
                value={value}
                items={wakalahs?.map((person) => {
                  return {
                    name: `${person.name} - ${person.contactNumber}`,
                    value: person.id,
                  };
                })}
              />
            )}
          />
        )}
        {/* {isFetching ? null : (
          <DropdownWithSearch
            items={wakalahs?.map((person) => {
              return {
                name: person.name,
                value: person.id,
              };
            })}
          />
        )} */}

        {errors.ownerId ? (
          <span className="text-sm text-red-700">This field is required</span>
        ) : null}
      </div>

      <div>
        <label htmlFor="maturityDate" className="text-sm">
          Maturity Date
        </label>
        <input
          {...register('maturityDate', { required: true })}
          type="date"
          min={new Date().toISOString().split('T')[0]}
          id="maturityDate"
          className="focus:ring-green-500 focus:border-green-500 block w-full px-2 sm:text-sm border-gray-400 rounded"
          placeholder="Enter maturity date"
        />
        {errors.maturityDate ? (
          <span className="text-sm text-red-700">This field is required</span>
        ) : null}
      </div>

      <div>
        <label htmlFor="tag" className="text-sm">
          Gold Tag
        </label>
        <input
          {...register('tag', { required: true })}
          type="text"
          id="tag"
          className="focus:ring-green-500 focus:border-green-500 block w-full px-2 sm:text-sm border-gray-400 rounded"
          placeholder="Enter gold tag"
        />
        {errors.tag ? (
          <span className="text-sm text-red-700">This field is required</span>
        ) : null}
        <div className="mb-3" />
      </div>

      <button
        type="submit"
        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-palette-primary hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {mutation.isLoading ? <LoadingSpinner /> : null}
        Issue Token
      </button>
    </form>
  );
};

export default CustodyFormModal;
