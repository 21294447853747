import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import logo from '../../../app/assets/images/mudarabah-logo.png';
import LoadingSpinner from '../../../app/components/common/LoadingSpinner';
import { AuthContext } from '../../../app/contexts/authContext';
import { ToastContext } from '../../../app/contexts/toastContext';
import history from '../../../app/helpers/history';
import { login } from '../../../app/repositories/auth';

export const LoginPage = () => {
  const { showToast } = useContext(ToastContext);
  let { from } = history.location.state || { from: { pathname: '/' } };
  const { setToken } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const mutation = useMutation(
    (formData) => login({ email: formData.email, password: formData.password }),
    {
      onSuccess: (result) => {
        if (
          result.data.data.user.role !== 'ADMIN' &&
          result.data.data.user.role !== 'CUSTODIAN'
        ) {
          throw new Error('You are not authorized to access this page.');
        }

        setToken(result.data.token);
        history.replace(from);
        // SecureStore.setItemAsync('jwt-token', result.data.token);
        // history.goBack();
      },
      onError: (error) => {
        showToast(
          'ERROR',
          'Failed to Authenticate',
          `${error.response?.data?.message || error.message}`
        );
      },
    }
  );

  const onSubmit = (formData) => {
    mutation.mutate(formData);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-20 w-auto" src={logo} alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Please login to continue
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  {...register('email', {
                    required: 'Email field cannot be empty',
                  })}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-palette-primary focus:border-palette-primary sm:text-sm"
                />
                {errors.email && (
                  <p className="text-red-600 text-xs">{errors.email.message}</p>
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  {...register('password', {
                    required: 'Password field cannot be empty',
                  })}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-palette-primary focus:border-palette-primary sm:text-sm"
                />
                {errors.password && (
                  <p className="text-red-600 text-xs">
                    {errors.password.message}
                  </p>
                )}
              </div>
            </div>

            <div className="flex items-center justify-between">
              {/* <div className="flex items-center">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember_me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div> */}
              <div />

              {/* <div className="text-sm">
                <a
                  href="#"
                  className="font-medium text-palette-primary hover:text-palette-primary"
                >
                  Forgot your password?
                </a>
              </div> */}
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-palette-primary hover:bg-palette-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-palette-primary"
              >
                {mutation.isLoading ? <LoadingSpinner /> : null}
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
