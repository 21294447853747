import axios from 'axios';

export const api = axios.create({
  // baseURL: 'http://localhost:3000/api/v1',
  // baseURL: 'https://api.aurachnid.com/v1',
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000/v1'
      : 'https://api.aurachnid.com/v1',
  timeout: 60000,
});
