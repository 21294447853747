import { api } from '../api/api';

export const fetchCustodies = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/custodies`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const fetchCustodyStats = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/custodies/stats`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const addCustody = async (formValues) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');
  const { jewelryName, jewelryWeight, maturityDate, tag, ownerId } = formValues;

  return api.post(
    '/custodies',
    {
      jewelryName,
      jewelryWeight,
      maturityDate: new Date(maturityDate),
      tag,
      ownerId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
};

export const retireCustody = async (id) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.delete(`/custodies/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const approveBuybackCustody = async (id) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.post(`/custodies/${id}/approve`, null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};
