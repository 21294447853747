import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { ModalContext } from '../../contexts/modalContext';
import { ToastContext } from '../../contexts/toastContext';
import { addCategory, editCategory } from '../../repositories/category';
import LoadingSpinner from '../common/LoadingSpinner';

const AddCategoryModal = ({ values }) => {
  const { hideModal } = useContext(ModalContext);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: values });
  const createMutation = useMutation((formValues) => addCategory(formValues), {
    onError: (error) => {
      showToast(
        'ERROR',
        'Failed to Create New Category',
        `${error.response?.data?.message || error.message}`
      );
    },
    onSuccess: () => {
      showToast('SUCCESS', 'Success', 'Product category created successfully');
      queryClient.invalidateQueries('categories');
      hideModal();
    },
  });
  const editMutation = useMutation(
    (formValues) => editCategory(values.id, formValues),
    {
      onError: (error) => {
        console.error(error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries('categories');
        hideModal();
      },
    }
  );

  const onSubmit = (formValues) => {
    if (!values) {
      createMutation.mutate(formValues);
    } else {
      editMutation.mutate(formValues);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex-1 flex flex-col px-2 py-3 space-y-6"
    >
      <div>
        <label htmlFor="name" className="text-sm">
          Name
        </label>
        <input
          {...register('name', { required: true })}
          type="text"
          id="name"
          className="focus:ring-green-500 focus:border-green-500 block w-full px-2 sm:text-sm border-gray-400 rounded"
          placeholder="Enter category name"
        />
        {errors.name ? (
          <span className="text-sm text-red-700">This field is required</span>
        ) : null}
      </div>

      <button
        type="submit"
        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-palette-primary hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {createMutation.isLoading || editMutation.isLoading ? (
          <LoadingSpinner />
        ) : null}
        Save
      </button>
    </form>
  );
};

export default AddCategoryModal;
