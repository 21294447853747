import { api } from '../api/api';

export const fetchUsers = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/users`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const fetchWakalahs = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/users/wakalah`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const fetchUserProfile = async (id) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/users/me`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const updateMerchantProfile = async (formValues) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');
  const {
    name,
    country,
    address,
    picName,
    idNumber,
    idImage,
    bankName,
    bankAccountName,
    bankAccountNumber,
  } = formValues;

  const formData = new FormData();
  name && formData.append('name', name);
  country && formData.append('country', country);
  address && formData.append('address', address);
  picName && formData.append('picName', picName);
  idNumber && formData.append('idNumber', idNumber);
  idImage && formData.append('idImage', idImage);
  bankName && formData.append('bankName', bankName);
  bankAccountName && formData.append('bankAccountName', bankAccountName);
  bankAccountNumber && formData.append('bankAccountNumber', bankAccountNumber);

  return api.patch(`/merchant/profile`, formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      enctype: 'multipart/form-data',
    },
  });
};

export const deactivateAccount = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.delete(`/merchant/profile/deactivate`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const uploadAvatarImage = async (imageUri) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');
  // const token = await SecureStore.getItemAsync('jwt-token');
  // const fileExt = imageUri.split('.');
  // const fileName = imageUri.split('/');

  const formData = new FormData();

  formData.append('merchantImage', imageUri);

  return api.post('/merchant/profile/avatar', formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      enctype: 'multipart/form-data',
    },
  });
};

export const verifyUserIdentity = async (id) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.post(
    `/users/identity/${id}/verify`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        enctype: 'multipart/form-data',
      },
    }
  );
};

export const fetchBankInformation = async () => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.get(`/users/bank`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const verifyBankInformation = async (id) => {
  const accessToken = localStorage.getItem('admin-mudarabah-token');

  return api.post(
    `/users/bank/${id}/verify`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        enctype: 'multipart/form-data',
      },
    }
  );
};
