import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { ModalContext } from '../../contexts/modalContext';
import { ToastContext } from '../../contexts/toastContext';
import { addCategory, editCategory } from '../../repositories/category';
import { verifyUserIdentity } from '../../repositories/user';
import LoadingSpinner from '../common/LoadingSpinner';

const VerifyUserIdentityModal = ({ user }) => {
  const { hideModal } = useContext(ModalContext);
  const { showToast } = useContext(ToastContext);
  const queryClient = useQueryClient();

  const verifyMutation = useMutation(
    () => verifyUserIdentity(user?.personalIdentity?.id),
    {
      onError: (error) => {
        showToast(
          'ERROR',
          'Failed to Verify User Identity',
          `${error.response?.data?.message || error.message}`
        );
      },
      onSuccess: () => {
        showToast('SUCCESS', 'Success', 'User identity verified');
        queryClient.invalidateQueries('users');
        hideModal();
      },
    }
  );

  console.log(user);

  return (
    <div className="flex-1 flex flex-col px-2 py-3 space-y-6">
      <div className="flex flex-row justify-between">
        <div className="flex-1">
          <p className="text-sm">Full Name</p>
          <h5 className="text-lg">{user?.personalIdentity?.fullname}</h5>
        </div>
        <div className="flex-1">
          <p className="text-sm">Identity Number</p>
          <h5 className="text-lg">{user?.personalIdentity?.idCardNumber}</h5>
        </div>
      </div>
      <div className="flex-1">
        <p className="text-sm">Identity Picture</p>
        <img
          alt={user?.personalIdentity?.fullname}
          src={user?.personalIdentity?.idCardImage}
        />
      </div>

      <button
        onClick={() => {
          verifyMutation.mutate();
        }}
        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-palette-primary hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {verifyMutation.isLoading ? <LoadingSpinner /> : null}
        Verify
      </button>
    </div>
  );
};

export default VerifyUserIdentityModal;
